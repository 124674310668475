import AddressModal from '@/components/organisms/AddressModal';
import useCart from '@/contexts/CartProvider';
import useLoginModel from '@/contexts/LoginModelProvider';
import useLabCart from '@labServices/context/CartContext';
import useToggle from '@labServices/hooks/use-toggle';
import Button from '@labtest/presentation/atoms/Button';
import Checkbox from '@labtest/presentation/atoms/Checkbox';
import CouponCodeInput from '@labtest/presentation/atoms/CouponCodeInput';
import Line from '@labtest/presentation/atoms/Line';
import SmallSpinner from '@labtest/presentation/atoms/Loaders/Spinners/smallSpinner';
import Modal from '@labtest/presentation/atoms/Modal';
import SideDrawer from '@labtest/presentation/atoms/SideDrawer';
import Address from '@labtest/presentation/molecules/Address';
import CartTestCard from '@labtest/presentation/molecules/CartTestCard';
import ModalHeader from '@labtest/presentation/molecules/ModalHeader';
import EmptyCart from '@labtest/presentation/organisms/EmptyCart';
import PackageDetailsDrawer from '../PackageDetailsDrawer';
import SampleCollectionAddress from '../SampleCollectionAddress';

import { cartStorageName } from '@/services/ecommerce/apis/config';
import { applyDiscountCoupon, updateHardCopyTestCart } from '@labServices/apis/lab-test-apis/cart';
import { useAuthContext } from '@labServices/context';
import { isEmptyArray } from '@labServices/utils/cmnHelper';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@labServices/utils/session';
import { PopularDiagnosticCentreImage } from '@labServices/utils/svg';
import { AdToCartTestSkeletonLoader } from '@labtest/presentation/atoms/Loaders/SkeletonLoaders/testSkeletonLoader';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import styles from './Cart.module.css';

const Cart = ({ closeCart }) => {
	const { userData } = useAuthContext();
	const [openDialogModal, handleOpenDialogModal, handleCloseDialogModal] = useToggle();
	const { cartData, setCartData, getCart, removeCartItem, loading, hardCopyLoading, setHardCopyLoading } =
		useLabCart();
	const { carts: webCart, setDrawerOpen } = useCart();
	const { setShowLoginModal } = useLoginModel();
	const [refetch, setRefetch] = useState(null);
	const [userAddress, setUserAddress] = useState(null);

	const [showAddressModal, setShowAddressModal] = useState(false);
	const [selectedTest, setselectedTest] = useState('');
	const [showMoreTests, setshowMoreTests] = useState(false);

	const [couponCode, setCouponCode] = useState('');
	const [showCouponCodeInput, setShowCouponCodeInput] = useState(false);
	const [isCouponCodeApplied, setIsCouponCodeApplied] = useState(false);

	const router = useRouter();
	const cartObj = getItemFromLocalStorage(cartStorageName);

	useEffect(() => {
		if (!isEmptyArray(cartData?.items)) {
			getCart();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webCart?.uc_address?.ul_location]);

	useEffect(() => {
		if (!!cartData?.promoCode) {
			setCouponCode(cartData?.promoCode || '');
			setIsCouponCodeApplied(true);
		} else {
			setShowCouponCodeInput(false);
			setIsCouponCodeApplied(false);
			setCouponCode('');
		}
	}, [cartData?.promoCode]);

	const addMoreTest = () => {
		router.push('/lab-test/tests');
	};

	const handleShowTests = (slug) => {
		setselectedTest(slug);
		setshowMoreTests(true);
	};

	const handleCloseTests = () => {
		setselectedTest('');
		setshowMoreTests(false);
	};

	const handleHardCopySelect = async (e) => {
		setHardCopyLoading(true);
		const updatedCartData = await updateHardCopyTestCart(e.target.checked);
		if (updatedCartData.success) {
			setCartData((prev) => ({ ...prev, ...updatedCartData.data }));
			setItemInLocalStorage(cartStorageName, updatedCartData?.data);
		}
		setHardCopyLoading(false);
	};

	const handleCouponCode = async (code) => {
		const updatedCartData = await applyDiscountCoupon(code);

		if (updatedCartData?.success) {
			setIsCouponCodeApplied(true);
			setCartData((prev) => ({ ...prev, ...updatedCartData?.data }));
			setItemInLocalStorage(cartStorageName, updatedCartData?.data);
		} else {
			setIsCouponCodeApplied(false);
			toast.error(updatedCartData?.message, {
				position: 'top-center'
			});
		}
	};

	const handelResetCouponCode = async () => {
		setCouponCode('');
		setIsCouponCodeApplied(false);

		const updatedCartData = await applyDiscountCoupon('');

		if (updatedCartData?.success) {
			setCartData((prev) => ({ ...prev, ...updatedCartData?.data }));
			setItemInLocalStorage(cartStorageName, updatedCartData?.data);
		}
	};

	if (!loading && isEmptyArray(cartData?.items && cartObj?.items)) {
		return <EmptyCart closeCart={closeCart} router={router} setDrawerOpen={setDrawerOpen} />;
	}

	return (
		<>
			<div className={styles.cart_wrap}>
				<div className={styles.cart_testcards_wrap}>
					{loading &&
						[...Array(1)]?.map((_, index) => (
							<div className='border-b1-grey200 rounded-10' key={index}>
								<AdToCartTestSkeletonLoader />
							</div>
						))}
					{!loading &&
						!isEmptyArray(cartData?.items && cartObj?.items) &&
						cartData?.items?.map((item) => (
							<CartTestCard
								key={item?.id}
								data={item}
								removeCartItem={removeCartItem}
								setShowLoginModal={setShowLoginModal}
								handleShowTests={handleShowTests}
							/>
						))}
				</div>
				{isEmptyArray(cartData?.items) && <p className='ltbody_lg__bold mb20'>No tests added in the cart yet!</p>}
				<Button variant='primary' onClick={addMoreTest} fullWidth>
					Add More Tests
				</Button>

				<div className={`${styles.popular_diagnostic_image_wrap} mt-20`}>
					<p className='ltbody_md__semibold ltGreyscale900 mb-10'>Trusted Lab Partner:</p>

					<div>{PopularDiagnosticCentreImage()}</div>
				</div>

				<div className={styles.order_address_wrap}>
					{/* {loading && (
            <div className={styles.spinner_wrap}>
              <Spinner size='sm' />
            </div>
          )} */}
					{/* {!isEmptyObject(webCart?.uc_address) && ( */}
					<Address
						data={{ ...webCart?.uc_address } || cartData?.userLocation || {}}
						defaultAddress={userData?.defaultAddress}
						changeable={true}
						setShowAddressModal={setShowAddressModal}
					/>
					{/* )} */}
				</div>

				<div className={styles.cart_checkout_hardcopy}>
					<div className={styles.cart_checkout_hardcopy_head}>
						<div className={styles.cart_checkout_hardcopy_checkbox}>
							{hardCopyLoading ? (
								<SmallSpinner />
							) : (
								<Checkbox checked={cartData?.isHardCopyRequired} onChange={handleHardCopySelect} />
							)}
							<p className='ltbody_lg__semibold'>Hard Copy of Report</p>
						</div>
						{/* <p className='ltbody_lg__semibold'>৳{cartData?.hardCopyConveyance}</p> */}
					</div>
					<p className='ltbody_md__regular'>
						Report wil be delivered within 3-4 working days. Hard Copy Conveyance are non-refundable once the
						reports have been dispatched.
					</p>
				</div>

				<div className={styles.cart_checkout_details}>
					<div className={styles.coupon_container}>
						<p onClick={() => setShowCouponCodeInput(!showCouponCodeInput)}>Have coupon code?</p>
						{(cartData?.promoCode || showCouponCodeInput) && (
							<CouponCodeInput
								onClickApply={handleCouponCode}
								onClickReset={handelResetCouponCode}
								applyButtonText={cartData?.promoCode || isCouponCodeApplied ? 'Remove' : 'Apply'}
								isCouponCodeApplied={isCouponCodeApplied}
								couponCode={couponCode}
								setCouponCode={setCouponCode}
							/>
						)}
					</div>
					{/* <Line /> */}

					{/* SubTotal */}
					<div className={styles.cart_checkout_details_item}>
						<p className='ltbody_md__semibold ltGreyscale900'>Subtotal</p>
						<p className='ltbody_md__semibold ltGreyscale900'>৳{cartData?.subtotalAmount}</p>
					</div>

					{/* Discount Applied */}
					<div className={styles.cart_checkout_details_item}>
						<p className='ltbody_md__semibold ltGreyscale900'>Discount Applied</p>
						<p className='ltbody_md__semibold  primaryBase'>
							{cartData?.priceDiscount > 0 ? `-৳${cartData?.priceDiscount}` : `৳${cartData?.priceDiscount}`}
						</p>
					</div>

					{/* Coupon Applied */}
					{cartData?.promoDiscount && cartData?.promoCode && (
						<div className={styles.cart_checkout_details_item}>
							<p className='ltbody_md__semibold ltGreyscale900'>Coupon Applied ({cartData?.promoCode})</p>
							<p className='ltbody_md__semibold  primaryBase'> -৳{cartData?.promoDiscount}</p>
						</div>
					)}

					{/* Rounding Off */}
					{cartData?.roundingOff != 0 && (
						<div className={styles.cart_checkout_details_item}>
							<p className='ltbody_md_regular ltGreyscale900'>Rounding Off</p>
							<p className='ltbody_md_regular'> -৳{cartData?.roundingOff}</p>
						</div>
					)}

					<Line />

					{/* Material Charge  */}
					{cartData?.labMaterialCharge != 0 && (
						<div className={styles.cart_checkout_details_item}>
							<p className='ltbody_md_regular'>Material Charge</p>

							<p className='ltbody_md_regular errorBase'>৳{cartData?.labMaterialCharge}</p>
						</div>
					)}

					{/* Sample Collection Conveyance */}
					<div className={styles.cart_checkout_details_item}>
						<p className='ltbody_md_regular ltGreyscale900'>Sample Collection Conveyance</p>
						<div className={styles.cart_checkout_details_item_smaple_discount}>
							{cartData?.collectionConveyance == 0 ? (
								<p className='ltbody_md_regular'>৳0</p>
							) : (
								<p className='ltbody_md_regular'>
									{cartData?.shouldApplyCollectionConveyance ? (
										<span className='errorBase'>৳{cartData?.collectionConveyance}</span>
									) : (
										<span className={styles.sample_collection_fee_container}>
											<del>৳{cartData?.collectionConveyance}</del>
											<span className=''>৳0</span>
										</span>
									)}
								</p>
							)}
						</div>
					</div>

					{/* Hard Copy Conveyance */}
					{cartData?.isHardCopyRequired && (
						<div className={styles.cart_checkout_details_item}>
							<p className='ltbody_md_regular ltGreyscale900'>Hard Copy Conveyance</p>
							{cartData?.hardCopyConveyance == 0 ? (
								<p className='ltbody_md_regular'>৳0</p>
							) : (
								<p className='ltbody_md_regular'>
									{cartData?.shouldApplyHardCopyConveyance ? (
										<span className='ltbody_md_regular errorBase'>৳{cartData?.hardCopyConveyance}</span>
									) : (
										<span className={styles.sample_collection_fee_container}>
											<del>৳{cartData?.hardCopyConveyance}</del>
											<span className=''>৳0</span>
										</span>
									)}
								</p>
							)}
						</div>
					)}

					<Line />

					{/* Arogga Cash Applied */}
					<div className={styles.cart_checkout_details_item}>
						<p className='ltbody_md_regular ltGreyscale900'>Arogga Cash Applied</p>
						<p className='ltbody_md_regular  primaryBase'>
							{cartData?.cash == 0 ? '' : '-'}৳{cartData?.cash}
						</p>
					</div>

					<Line dashed={`dashed`} />

					{/* Total Payable */}
					<div className={styles.cart_checkout_details_item}>
						<p className='ltbody_md__semibold ltGreyscale900'>Total Payable</p>
						<p className='ltbody_md__semibold ltGreyscale900'>
							৳{cartData?.isHardCopyRequired ? cartData?.totalAmount : cartData?.totalAmountWithoutConveyance}
						</p>
					</div>
				</div>
			</div>

			<SideDrawer title='Package Details' open={showMoreTests} onClose={() => setshowMoreTests(false)}>
				{selectedTest && <PackageDetailsDrawer data={selectedTest} onClose={() => setshowMoreTests(false)} />}
			</SideDrawer>

			{showAddressModal && <AddressModal isOpen={showAddressModal} setIsOpen={setShowAddressModal} hover={true} />}

			<Modal open={openDialogModal} closeModal={handleCloseDialogModal}>
				<ModalHeader title={'Sample Collection Address'} closeModal={handleCloseDialogModal} />
				{!userAddress && (
					<SampleCollectionAddress handleModalClose={handleCloseDialogModal} setRefetch={setRefetch} />
				)}
			</Modal>
		</>
	);
};

export default Cart;
