import Empty from '@/components/atoms/Empty';
import Radio from '@/components/atoms/Radio';
import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import AroggaAPI from '@/services/ecommerce/apis/AroggaAPI';
import AroggaSWR from '@/services/ecommerce/apis/AroggaSWR';
import { API_ACCOUNT, API_CART } from '@/services/ecommerce/apis/apiConstrant';
import { updateCartAddress as updateLabTestCartAddress } from '@labServices/apis/lab-test-apis/cart';
import useLabCart from '@labServices/context/CartContext';
import Form from 'ar-form';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { FaBriefcase } from 'react-icons/fa6';
import { MdHomeFilled, MdOutlineApartment, MdOutlineMoreHoriz } from 'react-icons/md';
import Button from '../../atoms/Button';
import Card from '../../atoms/Card';
import DropDown from '../../atoms/DropDown';
import Modal from '../../atoms/Modal';
import SpinnerWrapper from '../../atoms/SpinnerWrapper';
import AddAddressModal from '../AddAddressModal';
import styles from './AddressModal.module.scss';

interface IProps {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	hover?: boolean;
	parent?: string;
}

const AddressModal = ({ isOpen, setIsOpen, hover, parent }: IProps) => {
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const { user } = useAuth();
	const { cartData } = useLabCart();

	const { getCart, carts, setDrawerOpen, setDeliveryAddress, currentlocation }: any = useCart();
	const { data, isLoading, mutate } = AroggaSWR(API_ACCOUNT.ADDRESS.GET, 'GET', null, {
		revalidateOnMount: false
	});
	const [form] = Form.useForm();
	const alert = useAlert();

	const handelSubmit = (values) => {
		mutate();
		getCart();
	};

	useEffect(() => {
		mutate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateLabTestCart = async (id: string, locationId: string) => {
		try {
			const { status } = await updateLabTestCartAddress(id, locationId);
			return { success: status === 'success' };
		} catch (error) {
			return { success: true };
		}
	};

	const updateCartAddress = async (id: string, locationId: string) => {
		setLoading(true);
		const [{ data, status }, { success }] = await Promise.all([
			AroggaAPI.post(API_CART.UPDATE_CART_LOCATION, {
				uc_user_location_id: id
			}),
			updateLabTestCart(id, locationId)
		]);
		if (status === 'success') {
			alert.success({
				message: 'Address Updated Successfully',
				title: 'Success',
				confirmText: 'Ok',
				provider: 'toast'
			});
			setIsOpen(false);
			getCart();
			carts?.products?.length !== 0 && setDrawerOpen(true);
		}
		setLoading(false);
	};

	const updateAddress = async (values) => {
		setLoading(true);
		const { status } = await AroggaAPI.post(API_ACCOUNT.ADDRESS.UPDATE(values.ul_id), {
			...values,
			ul_default: 1
		});

		if (status === 'success') {
			setLoading(false);
			mutate();
			alert.success({
				message: 'Address Updated Successfully',
				title: 'Success',
				confirmText: 'Ok'
			});
		}
		setLoading(false);
	};

	const onDelete = (id: number, mutate: any) => {
		alert.warning({
			message: 'Do you want to delete this address?',
			title: 'Delete Address',
			cancelText: 'No',
			confirmText: 'Yes',
			onCancel: () => {
				alert.close();
			},
			onConfirm: async () => {
				setLoading(true);

				AroggaAPI.delete(API_ACCOUNT.ADDRESS.DELETE(id))
					.then(async (res) => {
						const nextLabCartAddress = res?.data;

						// UPDATE LAB CART ADDRESS
						if (nextLabCartAddress) {
							if (currentlocation?.ul_id === id) {
								await updateLabTestCartAddress(nextLabCartAddress?.ul_id, nextLabCartAddress?.l_id);
							}
						} else {
							await updateLabTestCartAddress('0', '0');
						}

						mutate();
						getCart();
						setLoading(false);
						alert.success({
							message: 'Address Deleted Successfully',
							title: 'Success',
							confirmText: 'Ok'
						});
					})
					.finally(() => {
						setLoading(false);
					});
			}
		});
	};

	const cartAddress = get(carts, 'uc_address', null);

	return (
		<>
			<Modal
				title='Address'
				isOpen={isOpen}
				onOk={() => setIsOpen(false)}
				footer={null}
				onClose={() => setIsOpen(false)}>
				<div className=' w-full p-30'>
					<SpinnerWrapper loading={isLoading || loading}>
						{data?.length !== 0 ? (
							data?.map((item, key) => (
								<Card
									key={key}
									headerStyle={{
										fontSize: 16,
										borderTopLeftRadius: 12,
										borderTopRightRadius: 12,
										backgroundColor:
											cartAddress?.u_id === item.ul_id || currentlocation?.ul_id === item.ul_id
												? '#FFFCF2'
												: ''
									}}
									title={
										<div className='d-flex '>
											{`${item.ul_type} Address`}
											{!!item.ul_default && (
												<Button
													label={<span className='text-primary fw-500'>Default</span>}
													style={{
														borderRadius: 30,
														backgroundColor: '#CEF1E6',
														border: 'none'
													}}
													type='primary'
													className='ml-10'
													size='small'
												/>
											)}
											{cartAddress?.u_id === item.ul_id || currentlocation?.ul_id === item.ul_id ? (
												<span className='text-primary fw-500 ml-5'>(Shipping Address)</span>
											) : (
												''
											)}
										</div>
									}
									className='mb-20'
									size='small'
									extra={
										<DropDown
											overlay={[
												{
													key: '1',
													label: 'Edit',
													onClick: () => {
														form.setFieldsValue({
															...item,
															id: item.ul_id
														});
														setShowModal(true);
													}
												},
												{
													key: '2',
													label: 'Make Default ',
													disabled: !!item.ul_default,
													selected: !!item.ul_default,
													onClick: () => {
														updateAddress(item);
													}
												},
												{
													key: '3',
													label: 'Delete',
													onClick: () => {
														onDelete(item.ul_id, mutate);
													}
												}
											]}
											placement='bottomRight'>
											<MdOutlineMoreHoriz size={24} />
										</DropDown>
									}>
									<div className='d-flex justify-space-between cursor-pointer'>
										<div className='d-flex justify-space-between gap-10'>
											<div
												className='bg-primary_bg h-48 w-48 rounded-full d-flex text-primary justify-center items-center'
												style={{
													minWidth: 48
												}}>
												{item.ul_type === 'Home' && <MdHomeFilled size={24} />}
												{item.ul_type === 'Office' && <FaBriefcase size={24} />}
												{item.ul_type === 'Home Town' && <MdOutlineApartment size={24} />}
											</div>
											<div className='d-flex flex-column gap-5 flex-warp'>
												<span>{item.ul_name}</span>
												<span>{item.ul_mobile}</span>
												<span>{item?.ul_location}</span>
											</div>
										</div>
										{hover && (
											<div className='d-flex justify-center align-items-center'>
												<div className={styles.radio}>
													<Radio.AddressRadio
														id={`ul_${item.ul_id}`}
														name='address'
														value={item.ul_id}
														checked={
															cartAddress?.u_id === item.ul_id || currentlocation?.ul_id === item.ul_id
														}
														onClick={() => {
															updateCartAddress(item.ul_id, item?.l_id);
															setDeliveryAddress(item);
														}}
													/>
												</div>
											</div>
										)}
									</div>
								</Card>
							))
						) : (
							<Card
								bodyStyle={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}>
								<Empty description='No Address Found' />
							</Card>
						)}
					</SpinnerWrapper>

					<Button
						className='mt-20 m-auto'
						label='Add New Address'
						type='primary'
						onClick={() => {
							setShowModal(true);
							form.setFieldsValue({
								ul_name: user?.u_name,
								ul_mobile: user?.u_mobile
							});
						}}
					/>
					{showModal && (
						<AddAddressModal
							onClose={() => setShowModal(false)}
							isOpen={showModal}
							form={form}
							parent={parent}
							refetch={handelSubmit}
							setDrawerOpen={setDrawerOpen}
							onfinish={() => {
								carts?.products?.length !== 0 && setDrawerOpen(true);
								setIsOpen(false);
							}}
						/>
					)}
				</div>
			</Modal>
		</>
	);
};

export default AddressModal;
