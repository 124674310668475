import { searchStorageName } from '@/services/ecommerce/apis/config';
import { useEffect, useState } from 'react';

const useSearchHistory = (): [string[], (newTerm: string) => void, () => void] => {
	const [history, setHistory] = useState<string[]>([]);

	useEffect(() => {
		// Load history from localStorage on mount
		const storedHistory = JSON.parse(localStorage.getItem(searchStorageName) || '[]') as string[];
		setHistory(storedHistory);
	}, []);

	const updateHistory = (newTerm: string): void => {
		if (newTerm.trim()) {
			let updatedHistory = history.filter((term) => term.toLowerCase() !== newTerm.toLowerCase());
			updatedHistory = [newTerm, ...updatedHistory].slice(0, 5);
			setHistory(updatedHistory);
			localStorage.setItem(searchStorageName, JSON.stringify(updatedHistory));
		}
	};

	const clearHistory = (): void => {
		setHistory([]);
		localStorage.removeItem(searchStorageName);
	};

	return [history, updateHistory, clearHistory];
};

export default useSearchHistory;
