'use client';

import useCart from '@/contexts/CartProvider';
import useHeaderVisibility from '@/services/ecommerce/hooks/useHeaderVisibility';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { MdPhoneInTalk } from 'react-icons/md';
export const Extra = ({ hiddenClass, activeClass }) => {
	const isHidden = useHeaderVisibility();
	const pathname = usePathname();
	useEffect(() => {
		const header = document.getElementById('header__bottom');
		if (header) {
			if (isHidden) {
				header.classList.add(hiddenClass);
			} else {
				header.classList.remove(hiddenClass);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isHidden]);
	useEffect(() => {
		console.log('%cStop!', 'color: red; font-size: 50px; font-weight: bold;');
		console.log(
			'%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable arogga.com features or "hack" someone\'s account, it is a scam and will give them access to your arogga.com account.',
			'color: red; font-size: 20px;'
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		const header = document.getElementById(pathname);
		const headerBottom = document.getElementById('header__bottom');
		const navbarItems = document.getElementById('navbar__items');
		const mainContent = document.getElementById('main-content');
		// container-fluid

		if (navbarItems) {
			const activeElement = navbarItems.querySelector(`.${activeClass}`);
			if (activeElement) {
				activeElement.classList.remove(activeClass);
			}
		}

		if (header) {
			header.classList.add(activeClass);
		}
		if (pathname.startsWith('/search')) {
			headerBottom.classList.add('hidden');
			if (mainContent) {
				mainContent.classList.add('mt-70');
			}
		} else {
			headerBottom.classList.remove('hidden');
			if (mainContent) {
				mainContent.classList.remove('mt-70');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, activeClass]);

	return (
		<div
			className='nav-call-for-order'
			onClick={() => {
				window.open('tel:09610016778', '_self');
			}}>
			<MdPhoneInTalk size={26} />
			<span>Call for Order</span>
		</div>
	);
};

export const UploadPrescriptionLink = () => {
	const { toggleUploadPrescriptionModal } = useCart();
	return (
		<div id={'/'} className={'nav_item'} onClick={toggleUploadPrescriptionModal}>
			<p className='navbar_text_size'>Upload Prescription</p>
		</div>
	);
};
