import { IC_DELETE } from '@/assets/icons';
import Button from '@/components/atoms/Button';
import Modal from '@/components/atoms/Modal';
import CartItem from '@/components/pageComponents/cartItems/CartItems';
import { generateQuantityOptions } from '@/services/ecommerce/utils/helper';
import { filter, get } from 'lodash';
import OrderSummary from '../OrderSummary/OrderSummary';
import styles from './CheckoutModal.module.scss';

const QuantityDecreasedContent = ({
	hasProducts,
	hasUnavailableProducts,
	filteredCarts,
	carts,
	userType,
	isDhakaCityForQty,
	cartAction,
	removeCart,
	setHasQuantityDecreased,
	router,
	handleOrder,
	onClose,
	setPaymentMethod,
	hasQuantityDecreased,
	setIsOpen
}) => (
	<Modal
		isOpen={hasQuantityDecreased}
		onClose={() => {
			onClose();
			setPaymentMethod(null);
			setHasQuantityDecreased(false);
		}}
		footer={null}
		width={600}
		maskClosable={false}
		title='Updated Shopping Cart'
		subTitle={'Item quantities updated as per available stock.'}
		bodyStyle={{
			borderRadius: '15px',
			padding: '0px 20px',
			maxHeight: 'calc(100vh - 200px)',
			overflowY: 'auto'
		}}>
		{(hasProducts || hasUnavailableProducts) && (
			<div className={styles.drawer__body__items}>
				{/*CartItem 3  */}
				{filteredCarts?.map((item: any, i: any) => {
					const isUnavailable = filter(carts?.unavailable_items, { pv: { id: item?.pv?.id } }).length > 0;
					return (
						<CartItem
							key={i}
							item={item}
							userType={userType}
							isDhakaCity={isDhakaCityForQty}
							cartAction={cartAction}
							options={generateQuantityOptions(
								item.pv[`pv_${userType}_min_qty`],
								item.pv[`pv_${userType}_max_qty`],
								item.pv[`pu_unit_label`],
								item?.pv?.pu_multiplier,
								item.pv[`pu_base_unit_label`]
							)}
							showItemsRight={!isUnavailable}
							removeIcon={!isUnavailable && <IC_DELETE onClick={() => removeCart(item?.pv?.id, item?.p_name)} />}
							cartsUcAddress={carts?.uc_address}
							currentPv={get(item, 'pv', {})}
							qty_decrease_message={item?.poi_stocks}
							isUnavailable={isUnavailable}
							showNotify={isUnavailable}
						/>
					);
				})}
			</div>
		)}

		{carts && carts?.po_item_count !== 0 ? (
			<div className='my-10'>
				<OrderSummary data={carts} />
			</div>
		) : null}

		{/* Fixed at the bottom */}

		{hasUnavailableProducts && !hasProducts ? (
			<div
				style={{
					position: 'sticky',
					bottom: 0,
					background: '#fff',
					width: '100%',
					paddingBottom: '10px',
					zIndex: 10
				}}>
				<p
					onClick={() => {
						setHasQuantityDecreased(false);
						setTimeout(() => {
							router.push('/');
						}, 1000);
					}}
					style={{
						backgroundColor: '#0E7673',
						color: 'white',
						padding: '12px 15px',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '5px',
						marginTop: '10px',
						position: 'sticky',
						bottom: '20',
						zIndex: '1',
						cursor: 'pointer'
					}}>
					Continue Shopping
				</p>
			</div>
		) : (
			<div
				style={{
					position: 'sticky',
					bottom: 0,
					background: '#fff',
					width: '100%',
					paddingBottom: '10px',
					zIndex: 10
				}}>
				<div>
					<Button
						type='primary'
						className='w-full'
						onClick={() => {
							setIsOpen(true);
							setHasQuantityDecreased(false);
							handleOrder();
						}}>
						Confirm Order: ৳{carts?.po_payable_total}
					</Button>
				</div>
			</div>
		)}
	</Modal>
);

export default QuantityDecreasedContent;
