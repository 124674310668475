import cls from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
type ApiResponse = {
	data: any;
	status: 'success' | 'fail';
	message?: string;
};

interface UploadButtonProps {
	addToCart?: number;
	className?: string;
	onSuccess?: (message?: string) => void;
	setLoading?: (loading: boolean) => void;
	label: React.ReactNode;
	onChooseFile?: (files: FileList) => void;
	control?: any;
	accept?: string;
	isMultiple?: boolean;
}

const UploadButton = ({
	addToCart = 0,
	className,
	onSuccess,
	setLoading,
	label,
	onChooseFile,
	control,
	accept,
	isMultiple
}: UploadButtonProps) => {
	const [files, setFiles] = React.useState<FileList | null>(null);

	const handlePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files.length > 0) {
			setFiles(files);
			onChooseFile?.(files);
		}
	};

	return (
		<>
			<label className={cls(styles.upload_prescription, className)}>
				<div className={styles.upload_prescription__icon}>
					<svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M12.7921 0.666992H34.5621C42.4955 0.666992 47.0221 5.14699 47.0221 13.1037V34.897C47.0221 42.807 42.5188 47.3337 34.5855 47.3337H12.7921C4.83547 47.3337 0.355469 42.807 0.355469 34.897V13.1037C0.355469 5.14699 4.83547 0.666992 12.7921 0.666992ZM25.6021 25.937H32.2288C33.3021 25.9137 34.1655 25.0503 34.1655 23.977C34.1655 22.9037 33.3021 22.0403 32.2288 22.0403H25.6021V15.4603C25.6021 14.387 24.7388 13.5237 23.6655 13.5237C22.5921 13.5237 21.7288 14.387 21.7288 15.4603V22.0403H15.1255C14.6121 22.0403 14.1221 22.2503 13.7488 22.6003C13.3988 22.9737 13.1888 23.4613 13.1888 23.977C13.1888 25.0503 14.0521 25.9137 15.1255 25.937H21.7288V32.5403C21.7288 33.6137 22.5921 34.477 23.6655 34.477C24.7388 34.477 25.6021 33.6137 25.6021 32.5403V25.937Z'
							fill='#0E7673'
						/>
					</svg>
				</div>
				<div className={styles.upload_prescription__text}>
					<span>{label}</span>
					<small>Add up to 2 MB</small>
				</div>
				<input
					type='file'
					multiple={isMultiple}
					accept={accept || 'image/* , application/pdf'}
					name='file'
					style={{ display: 'none' }}
					onChange={handlePreview}
				/>
			</label>
			{files && files.length > 0 && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 20,
						backgroundColor: '#F5F5F5',
						padding: 10,
						borderRadius: 10
					}}>
					{Array.from(files).map((file, index) => (
						<div
							key={index}
							style={{
								display: 'flex',
								justifyContent: 'space-between'
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%'
								}}>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										gap: 10
									}}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											flexDirection: 'column',
											fontSize: 30,
											color: '#0E7673'
										}}>
										{file.type.includes('image') ? (
											<i className='far fa-file-image'></i>
										) : (
											<i className='far fa-file-pdf'></i>
										)}
									</div>
									<span
										style={{
											fontSize: 12,
											display: 'block',
											wordWrap: 'break-word',
											width: '100%',
											textAlign: 'center',
											textOverflow: 'ellipsis',
											overflow: 'hidden'
										}}>
										{file.name}
									</span>
								</div>

								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										fontSize: 30,
										color: '#0E7673',
										cursor: 'pointer'
									}}
									onClick={() => {
										const newFiles = Array.from(files) as any;
										newFiles.splice(index, 1);
										setFiles(newFiles.length > 0 ? newFiles : []);
										control?.onChange(newFiles.length > 0 ? newFiles : null);
									}}>
									<i className='far fa-times-circle'></i>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default UploadButton;
