'use client';

import { AuthProvider } from '@/contexts/AuthProvider';
import { CartProvider } from '@/contexts/CartProvider';
import { LoginModelProvider } from '@/contexts/LoginModelProvider';
import { ProductProvider } from '@/contexts/ProductProvider';
import { StickyCartProvider } from '@/contexts/StickyCartProvider';
import { AuthContextProvider } from '@labServices/context';
import { CartContextProvider } from '@labServices/context/CartContext';
import { SessionProvider } from 'next-auth/react';
const LayoutWrapper = ({ children }) => {
	return (
		<SessionProvider>
			<AuthProvider>
				<AuthContextProvider>
					<LoginModelProvider>
						<CartProvider>
							<CartContextProvider>
								<StickyCartProvider>
									<ProductProvider>{children}</ProductProvider>
								</StickyCartProvider>
							</CartContextProvider>
						</CartProvider>
					</LoginModelProvider>
				</AuthContextProvider>
			</AuthProvider>
		</SessionProvider>
	);
};

export default LayoutWrapper;
