'use client';

import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import useLabTestPixalEvent from '@/services/event/useLabTestPixalEvent';

import { cartStorageName, userStorageName } from '@/services/ecommerce/apis/config';
import {
	addTestToCart,
	deleteTestCartItem,
	getTestsCart,
	updateTestCartItem
} from '@labServices/apis/lab-test-apis/cart';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@labServices/utils/session';
import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuthContext } from '.';

export const CartContext = createContext<any>(null || '');

export const CartContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [cartData, setCartData] = useState<any>({});
	const { setcartTotalPriceItems } = useAuthContext();
	const { carts: webCart } = useCart();
	const { isLogged } = useAuth();
	const [loading, setLoading] = useState<boolean>(true);
	const [hardCopyLoading, setHardCopyLoading] = useState<boolean>(false);

	const { LabTestAddToCartEvent } = useLabTestPixalEvent();

	const cartAction = async (testId, testData, num, onError) => {
		const cart = cartData;
		const isAlreadyInCart = cart?.items?.find((item) => item.test_id === testId);

		const fetcher = isAlreadyInCart ? updateTestCartItem : addTestToCart;
		const res = await fetcher(testId, num);

		if (res?.success) {
			setcartTotalPriceItems((prev) => ({
				...prev,
				itemCount: res.data.itemCount,
				totalAmount: res.data.totalAmount
			}));
			setCartData(res.data);
			setItemInLocalStorage(cartStorageName, res?.data);

			// Pixel Event Handler
			LabTestAddToCartEvent({
				testData,
				patientCount: num
			});

			if (isAlreadyInCart) {
				toast.success(res?.message, { position: 'top-center' });
			} else {
				toast.success(res?.message, { position: 'top-center' });
			}
			return;
		}
		onError && onError(res);
	};

	const removeCartItem = async (testId, action = null) => {
		const res = await deleteTestCartItem(testId);

		if (res?.success) {
			setcartTotalPriceItems((prev) => ({
				...prev,
				itemCount: res.data.itemCount,
				totalAmount: res.data.totalAmount
			}));
			setCartData(res?.data);
			setItemInLocalStorage(cartStorageName, res?.data);
			toast.success(res?.message, {
				position: 'top-center'
			});
		} else {
			toast.error(res.message, { position: 'top-center' });
		}
		action && action();
	};

	const getCart = async () => {
		setLoading(true);
		// setCartData((prev) => ({ ...prev, items: [] }));

		const res = await getTestsCart();
		if (res?.success) {
			setCartData(res?.data);
			setItemInLocalStorage(cartStorageName, res?.data);
		} else {
			setCartData({});
		}
		setLoading(false);
	};

	useEffect(() => {
		const user = getItemFromLocalStorage(userStorageName);
		if (isLogged && user) getCart();
	}, [isLogged]);

	return (
		<CartContext.Provider
			value={{
				cartData,
				loading,
				setLoading,
				hardCopyLoading,
				setHardCopyLoading,
				getCart,
				cartAction,
				setCartData,
				removeCartItem
			}}>
			{children}
		</CartContext.Provider>
	);
};

const useLabCart = () => {
	const cartContext = useContext(CartContext);
	if (!cartContext && typeof window !== 'undefined') {
		console.error('CartContext is not available. Make sure CartContextProvider is properly set up.');
		return {};
	} else {
		return cartContext;
	}
};

export default useLabCart;
