import Card from '@/components/atoms/Card';
import AcknowledgeCongratulations from '@/components/dynamics/acknowledgeCongratulations/AcknowledgeCongratulations';
import { MdOutlineClose } from 'react-icons/md';

export const OrderSuccess = ({ data, onClose }) => {
	return (
		<div>
			<div
				style={{
					position: 'absolute',
					right: 0,
					top: 0,
					padding: '10px',
					zIndex: 10,
					color: '#98A2B3'
				}}>
				<div style={{ cursor: 'pointer' }}>
					<MdOutlineClose size={24} className='hover-text-error' onClick={onClose} />
				</div>
			</div>
			<Card
				bordered={false}
				bodyStyle={{
					padding: '16px',
					margin: '0px 0px 0px 0px'
				}}
				size='small'>
				<AcknowledgeCongratulations
					title='Congratulations!'
					message={
						<>
							Order placed successfully by
							<strong style={{ color: '#1F2937' }}> &quot;Cash On Delivery&quot;.</strong>
						</>
					}
					isCollapsible={false}
					step={1}
					data={{
						po_payable_total: data?.po_payable_total,
						po_id: data?.po_id,
						delivery_time: data?.delivery_time
					}}
				/>
			</Card>
		</div>
	);
};
