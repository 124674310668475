'use client';

import React, { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Button from '../Button';
import styles from './Radio.module.scss';

interface RadioProps {
	value: string | number;
	name?: string;
	children?: ReactNode;
	checked?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
	defaultChecked?: boolean;
	icon?: ReactNode;
	[key: string]: any;
}

const Radio: React.FC<RadioProps> & {
	Button?: React.FC<RadioButtonProps>;
	Group?: React.FC<GroupProps>;
	style?: React.CSSProperties;
} = ({ value, children, name, style, ...props }) => {
	if (props.hidden) {
		return <input type='radio' value={value} {...props} style={{ display: 'none' }} />;
	}
	return (
		<label className={styles.radioContainer}>
			<input className={styles.radioInput} type='radio' value={value} name={name} {...props} />
			<span className={styles.radioPill} onClick={props?.onClick} style={style}></span>
			<span>{children}</span>
		</label>
	);
};

interface RadioButtonProps extends RadioProps {
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({ value, children, checked, onChange, ...props }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleButtonClick = () => {
		const input = inputRef.current;
		if (input && !checked) {
			input.checked = true;
			onChange &&
				onChange({
					target: inputRef.current
				} as React.ChangeEvent<HTMLInputElement>);
		}
	};

	return (
		<Button className={styles.button} type={checked ? 'primary' : 'light'} onClick={handleButtonClick} {...props}>
			<input ref={inputRef} type='radio' value={value} {...props} style={{ display: 'none' }} />
			<span>{children}</span>
		</Button>
	);
};

interface GroupProps {
	children?: ReactNode;
	defaultValue?: string;
	value?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	optionType?: 'default' | 'button';
	name: string;
	options?: { value: string; label: any }[];
	style?: React.CSSProperties;
}

const Group: React.FC<GroupProps> = ({
	children,
	defaultValue,
	value,
	onChange,
	optionType = 'default',
	name,
	...props
}) => {
	const [internalValue, setInternalValue] = useState<string | null>(defaultValue);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newValue: string | null = e.target.value === internalValue ? null : e.target.value;

			if (newValue !== null) {
				e.target.value = newValue;
			}

			if (onChange) {
				// e.target;
				onChange(e);
			}

			setInternalValue(newValue);
		},
		[onChange, internalValue]
	);

	useEffect(() => {
		if (defaultValue !== undefined) {
			setInternalValue(defaultValue);
		}
	}, [defaultValue]);
	const renderChildren = () => {
		const Component = optionType === 'button' ? RadioButton : Radio;
		if (children) {
			return React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, {
						Component,
						...child.props,
						name,
						onChange: handleChange,
						checked: child.props.disabled ? false : child.props.value === internalValue
					});
				}
				return null; // Return null for children that aren't valid React elements
			});
		}

		if (props.options) {
			return props.options.map((option) => (
				<Component key={option.value} value={option.value} name={name} checked={option.value === internalValue}>
					{option.label}
				</Component>
			));
		}
	};

	return (
		<div onChange={handleChange} {...props} style={{ display: 'flex', gap: '10px', ...props.style }}>
			{renderChildren()}
		</div>
	);
};
const AddressRadio: React.FC<RadioProps> = ({ value, children, name, onClick, style, onChange, id, ...props }) => {
	return (
		<label className={styles.radioContainer} htmlFor={id}>
			<input className={styles.radioInput} type='radio' value={value} name={name} {...props} id={id} />
			<span className={styles.radioPill} onClick={onClick} style={style}></span>
		</label>
	);
};
export default Object.assign(Radio, {
	Button: RadioButton,
	Group,
	AddressRadio
});
